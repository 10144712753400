
import { defineComponent, ref, computed, onMounted } from 'vue';
import { MenuComponent } from '@/assets/ts/components';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { useStore } from 'vuex';
import moment from "moment";
import InvoiceService from '@/core/services/car/InvoiceService';
import MasterService from '@/core/services/car/MasterService';
import AIExplore from '@/components/invoice/Add-Invoice.vue';
import LocalStorageServices from '@/core/services/LocalStorageServices';
import Invoice from '@/models/car/IInvoiceList';
import ViewDocumentsModal from '@/components/modals/forms/ViewDocumentsModal.vue';
import UploadInvoiceDocument from '@/components/modals/forms/UploadInvoiceDocument.vue';
import { onBeforeRouteLeave } from 'vue-router';
import { Mutations } from '@/store/enums/StoreEnums';
import ShowQuoteRefNo from '@/components/reusables/showQuoteRefNo.vue';
import { getMonthDate, customFunction, roles, registration } from '@/store/stateless/store';
import ReportService from '@/core/services/car/ReportService';

interface InvoiceBodyParam {
  per_page: string;
  agent: string;
  payment_type: any;
  is_amend: number;
  keyword: string;
  start_date: string;
  end_date: string;
  status: string;
  is_manual_order: any;
  is_discrepancy: number | null;
}
// interface TransferLeadsParam {
//   customer_ids: [];
//   agent_id: string;
//   notes: string;
// }

export default defineComponent({
  name: 'invoice-list',
  components: {
    AIExplore,
    ViewDocumentsModal,
    UploadInvoiceDocument
  },
  data() {
    return {
      shortcuts: [
        {
          text: 'Last week',
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          })()
        },
        {
          text: 'Last month',
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          })()
        },
        {
          text: 'Last 3 months',
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          })()
        }
      ],
      value1: '',
      // value2: "",
      invoiceStatus: ['-', 'Pending', 'Completed', 'Failed']
    };
  },
  setup() {
    // let customers_obj = ref([]);
    let responseResult = ref<any>({});
    responseResult.value.success = false;
    responseResult.value.failed = false;
    responseResult.value.execute = false;
    const store = useStore();
    let currentInvoiceId = ref<any>();
    let invoiceList = ref<Invoice>();
    let invoiceListCount = ref({});
    let activeLink = ref('All');
    let paymentTypes = ref<any>();
    let invoiceTypes = ref<any>();
    let userObj: any = {};
    let userStr: any = {};
    let bodyParameters = {} as InvoiceBodyParam;
    const value2 = ref<Array<string>>([]);


    bodyParameters = {
      per_page: '50',
      agent: 'all',
      payment_type: -1,
      is_amend: -1,
      keyword: '',
      start_date: moment().startOf("year").format("YYYY-MM-DD"),
      end_date: moment().endOf("month").format("YYYY-MM-DD"),
      status: '2',
      is_manual_order: null,
      is_discrepancy: null
    };

    let exportUrl = ref<string>('');

    let disableSaveButton = computed(() => {
      return store.getters.getDisableButtonStatus;
    });

    userStr = LocalStorageServices.getUser();
    userObj = JSON.parse(userStr);
    const permissions = computed(() => {
      return store.state.AuthModule.aclPermission;
    });
    onMounted(() => {
      if (!roles.approvedInvoice.includes(roles.userId()))
        window.location.href = '/#/404';

      MenuComponent.reinitialization();
      console.log(process.env.IS_ADMIN);
      setCurrentPageBreadcrumbs('Approved Invoices', ['Reports']);
      // API Action to get customers list

      bodyParameters.keyword = '';
      bodyParameters.agent = [1, 9].includes(userObj.role_id) ? 'all' : 'mine';
      value2.value = getMonthDate.currentMonth();
      const types = [
        { label: 'All Invoice Types', value: 'all' },
        { label: 'New', value: 'new' },
        { label: 'Amend', value: 'amend' },
        { label: 'Manual Order', value: 'manual_order' }
      ];
      invoiceTypes.value = types;
      selectedInvoiceType.value = 'all';
      // console.log(value2.value);
      bodyParameters.start_date = value2.value[0];
      bodyParameters.end_date = value2.value[1];
      getInvoiceList(bodyParameters);

      Promise.all([MasterService.getPaymentTypes()]).then((data) => {
        if (
          permissions.value.is_sales_agent ||
          permissions.value.is_manager ||
          permissions.value.is_team_leader
        ) {
          const allData = {
            label: 'All Transaction Type',
            value: 'mine'
          };
          selectedPaymentType.value = 'mine';
          let result = store.getters.getPaymentTypeList;
          result.unshift(allData);
          paymentTypes.value = result;
        }
        if (
          permissions.value.is_admin ||
          permissions.value.is_accountant ||
          permissions.value.is_manager || permissions.value.is_qca
        ) {
          const allData = {
            label: 'All Transaction Type',
            value: 'all'
          };
          selectedPaymentType.value = 'all';
          let result = store.getters.getPaymentTypeList;
          result.unshift(allData);
          paymentTypes.value = result;
        }
      });
    });

    let loadApprovalInformation = computed(() => {
      return store.getters.getApprovalListingDom;
    });

    const getPaginationUrl = (index) => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      // let pagenationUrl = index.split("=");
      bodyParameters.keyword = '';
      // InvoiceService.getInvoiceList(bodyParameters, pagenationUrl[1]);
      Promise.all([
        // InvoiceService.getInvoiceList(bodyParameters, currentPage.value)
        ReportService.getInvoiceList(bodyParameters, currentPage.value)
      ]).then((data) => {
        invoiceList.value = store.getters.getInviceList;
      });
    };
    const onPerPageChange = (event) => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      bodyParameters.per_page = event.target.value;
      // InvoiceService.getInvoiceList(bodyParameters);
      getInvoiceList(bodyParameters);
    };

    const exportReport = () => {
      // bodyParameters.page = 1;
      Promise.all([
        ReportService.exportingApprovedInvoiceReport(bodyParameters)
      ]).then((data) => {
        let exportResult = computed(() => {
          return store.getters.getExport;
        });
        exportUrl.value = '';
        const DownloadReportRoute = process.env.VUE_APP_API_URL + 'download-report/' + exportResult.value
        window.open(DownloadReportRoute)
      });
    };

    const getInvoiceListFor = (listFor) => {
      bodyParameters.agent = listFor;
      bodyParameters.keyword = '';
      // InvoiceService.getInvoiceList(bodyParameters);
      getInvoiceList(bodyParameters);
      // CustomerService.getCustomerStatusCount(store,bodyParameters);
      // CustomerService.getDueDateTaskCount(store,bodyParameters);
    };

    const getPaymentTypeFor = (listFor) => {
      if (listFor.target.value === 'all' || listFor.target.value === 'mine') {
        bodyParameters.agent = listFor.target.value;
        bodyParameters.payment_type = -1;
        // bodyParameters.keyword = "";
      } else {
        // if(listFor.target.value == 2) {
        //   bodyParameters.payment_type = [2, 6];
        // } else {
          bodyParameters.payment_type = listFor.target.value;
        // }
        // bodyParameters.keyword = "";
      }
      getInvoiceList(bodyParameters);
    };
    const getInvoiceTypeFor = (listFor) => {
      console.log(listFor);
      switch (listFor.target.value) {
        case 'all':
          getAllInvoiceListFor();
          break;
        case 'new':
          getAmendInvoiceListFor('0');
          break;
        case 'amend':
          getAmendInvoiceListFor('1');
          break;
        case 'manual_order':
          getManualOrders();
          break;
        case 'discrepancy':
          getPremiumDescripancy();
          break;
      }
    };

    const getAmendInvoiceListFor = (listFor) => {
      activeLink.value = listFor;
      isActivePremium.value = false;
      bodyParameters.is_discrepancy = listFor == 0 ? 0 : null;
      bodyParameters.is_amend = listFor;
      // bodyParameters.keyword = '';
      bodyParameters.is_manual_order = null;

      // InvoiceService.getInvoiceList(bodyParameters);
      getInvoiceList(bodyParameters);
    };
    const getAllInvoiceListFor = () => {
      // bodyParameters = {
      //   per_page: "50",
      //   agent: "mine",
      //   payment_type: -1,
      //   is_amend: -1,
      //   keyword: "",
      //   start_date: "",
      //   end_date: "",
      //   status: "2",
      //   is_manual_order: null,
      //   is_discrepancy: null
      // };
      bodyParameters.agent = 'mine';
      bodyParameters.payment_type = -1;
      bodyParameters.is_amend = -1;
      bodyParameters.is_discrepancy = null;
      bodyParameters.is_manual_order = null;
      bodyParameters.agent = [1, 9].includes(userObj.role_id) ? 'all' : 'mine';
      getInvoiceList(bodyParameters);
    };

    const getInvoiceListPeriod = (listPeriod) => {
      if (listPeriod) {
        var startTS = new Date(listPeriod[0]);
        var endTS = new Date(listPeriod[1]);
        bodyParameters.start_date = moment(startTS.getFullYear() + "-" + (startTS.getMonth() + 1) + "-" + startTS.getDate()).format("YYYY-MM-DD");
        bodyParameters.end_date = moment(endTS.getFullYear() + "-" + (endTS.getMonth() + 1) + "-" + endTS.getDate()).format("YYYY-MM-DD");
      } else {
        bodyParameters.start_date = '';
        bodyParameters.end_date = '';
      }
      bodyParameters.keyword = '';
      // InvoiceService.getInvoiceList(bodyParameters);
      getInvoiceList(bodyParameters);
    };

    const search = ref<string>('');
    const searchInvoice = () => {
      // bodyParameters = {
      //   per_page: "50",
      //   agent: "mine",
      //   payment_type: -1,
      //   is_amend: -1,
      //   keyword: "",
      //   start_date: "",
      //   end_date: "",
      //   status: "2",
      //   is_manual_order: null,
      //   is_discrepancy: null
      // };
      bodyParameters.keyword = search.value;
      // value2.value = []
      // activeLink.value = 'All';
      // InvoiceService.getInvoiceList(bodyParameters);
      getInvoiceList(bodyParameters);
    };
    const selectedPaymentType = ref('');
    const selectedInvoiceType = ref('');
    const resetBodyParam = () => {
      activeLink.value = 'All';
      value2.value = [];
      search.value = '';
      bodyParameters.per_page = '50';
      bodyParameters.agent = userObj.role_id == 2 ? 'mine' : 'all';
      bodyParameters.payment_type = -1;
      bodyParameters.is_amend = -1;
      bodyParameters.is_manual_order = null;
      bodyParameters.keyword = '';
      bodyParameters.start_date = moment().startOf("year").format("YYYY-MM-DD");
      bodyParameters.end_date = moment().endOf("month").format("YYYY-MM-DD");
      bodyParameters.is_discrepancy = null;
      isActivePremium.value = false;

      if (
        permissions.value.is_sales_agent ||
        permissions.value.is_team_leader
      ) {
        selectedPaymentType.value = 'mine';
      }
      if (
        permissions.value.is_admin ||
        permissions.value.is_accountant ||
        permissions.value.is_manager
      ) {
        selectedPaymentType.value = 'all';
      }
      selectedInvoiceType.value = 'all';

      // InvoiceService.getInvoiceList(bodyParameters);
      getInvoiceList(bodyParameters);
    };

    const getInvoiceList = (bodyParameters) => {
      // console.log(bodyParameters)
      currentPage.value = 1;
      Promise.all([
        // InvoiceService.getInvoiceListCount(bodyParameters),
        ReportService.getInvoiceList(bodyParameters, currentPage.value)
      ]).then((data) => {
        invoiceList.value = store.getters.getInviceList;
        // invoiceListCount.value = store.getters.getInvoiceCount;
      });
    };

    const showAction = () => {
      //
    };
    const userPermissions = () => {
      return store.getters.getUserPermission;
    };

    const getManualOrders = () => {
      activeLink.value = 'ManualOrder';
      isActivePremium.value = false;
      bodyParameters.is_manual_order = 1;
      bodyParameters.is_discrepancy = null;
      bodyParameters.is_amend = -1;
      getInvoiceList(bodyParameters);
    };

    const isActivePremium = ref<boolean>(false);

    const getPremiumDescripancy = () => {
      activeLink.value = 'Discrepancy';
      isActivePremium.value = true;
      bodyParameters.is_discrepancy = 2;
      bodyParameters.is_manual_order = [0, 1];
      bodyParameters.is_amend = -1;
      getInvoiceList(bodyParameters);
    };

    const paginatioObject = computed(() => {
      return store.getters.getInvoicePaginationObject;
    });

    // onBeforeRouteLeave(() => {
    //   store.commit(Mutations.SET_AI_LOADING, true);
    // });

    const currentPage = ref(1);

    const loading = computed(() => {
      return store.getters.getARLoading;
    });

    const load = async (row, treeNode: unknown, resolve) => {
      console.log(row);
      let parent = false;
      let isManualOrder = bodyParameters.is_manual_order == '1' ? 1 : 0;
      if (isManualOrder === 1) {
        parent = row.parent_invoice_id === 0 ? false : true;
      } else {
        parent = row.is_amend === 0 ? false : true;
      }
      const result = await InvoiceService.getChildInvoice({
        parent_invoice_id: row.is_amend === 0 ? row.id : row.parent_invoice_id,
        parent,
        invoice_id: row.is_amend === 1 ? row.id : row.parent_invoice_id,
        is_manual_order: isManualOrder,
        status: 2
      });
      resolve(result);
    };

    const currency = (e) => {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: registration.currency
      });

      return formatter.format(e).replace(registration.currency, "");
    };

    return {
      registration,
      roles,
      currency,
      customFunction,
      load,
      loading,
      value2,
      isActivePremium,
      getPremiumDescripancy,
      paginatioObject,
      currentPage,
      selectedPaymentType,
      selectedInvoiceType,
      permissions,
      loadApprovalInformation,
      getPaymentTypeFor,
      getInvoiceTypeFor,
      getAmendInvoiceListFor,
      showAction,
      search,
      searchInvoice,
      onPerPageChange,
      getPaginationUrl,
      getInvoiceListFor,
      bodyParameters,
      getInvoiceListPeriod,
      resetBodyParam,
      responseResult,
      userPermissions,
      invoiceList,
      currentInvoiceId,
      paymentTypes,
      invoiceTypes,
      getInvoiceList,
      getManualOrders,
      getAllInvoiceListFor,
      activeLink,
      invoiceListCount,
      exportUrl,
      exportReport,
      disableSaveButton
    };
  },
  methods: {
    indexMethod(index) {
      return (
        this.paginatioObject.current_page * this.paginatioObject.per_page -
        this.paginatioObject.per_page +
        index +
        1
      );
    }
  }
});
